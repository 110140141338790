import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'; // https://github.com/auth0/auth0-spa-js
import { isBrowser } from '@knapsack/utils';

type Auth0Scope =
  | 'openid'
  | 'profile'
  | 'email'
  | 'read:user'
  | 'write:user'
  | 'offline_access';
const scopes: Auth0Scope[] = [
  'openid',
  'profile',
  'email',
  'read:user',
  'write:user',
  'offline_access',
];

const { AUTH_AUDIENCE = 'https://db.knapsack.cloud/v1/graphql' } = process.env;

const config: Auth0ClientOptions = {
  domain: 'auth.knapsack.cloud',
  clientId: 'mESOJI3993dm8JCPRuxddyR6X4okwk6w',
  authorizationParams: {
    audience: AUTH_AUDIENCE,
    scope: scopes.join(' '),
    redirect_uri: isBrowser ? window.location.origin : '',
  },
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true, // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
  cookieDomain: '.knapsack.cloud',
};

export const auth0 = new Auth0Client(config);

/**
 * This data is stored before the user is redirected away and then
 * will be retrieved from `auth0.handleRedirectCallback()` when the user is redirected back.
 */
export interface Auth0AppState {
  /** root relative url */
  preRedirectUrl: string;
}

export const loginWithRedirect = async (connection?: string): Promise<void> => {
  const appState: Auth0AppState = {
    // Turning into root relative path while preserving query params; surprised
    // that's not in `window.location` too!
    preRedirectUrl: window.location.href
      .replace(window.location.hash, '') // strip #access_token=lskdjsldkjdf
      .replace(window.location.origin, ''), // strip app.knapsack.cloud, etc
  };
  await auth0.loginWithRedirect<Auth0AppState>({
    authorizationParams: {
      redirect_uri: window.location.origin,
      // undefined or empty strings sent on the `connection` key are taken literally
      // at the auth0 backend. Make sure the key does not exist if there is no connection
      ...(connection ? { connection } : {}),
    },
    appState,
  });
};

export const loginWithRedirectTHD = () =>
  loginWithRedirect('Knapsack-SAML-Auth0-IdP-THD');

export const loginWithRedirectEJ = () =>
  loginWithRedirect('Knapsack-SAML-Auth0-IdP-Edward-Jones');

export const loginWithRedirectGsk = () =>
  loginWithRedirect('US-Websites-Prod-Knapsack-S683344016');

export const loginWithRedirectGskDigitalChannels = () =>
  loginWithRedirect('Knapsack-SAML-Auth0-IdP-GSK-Digital-Channels');

export const loginWithRedirectOpisnet = () =>
  loginWithRedirect('Knapsack-SAML-Auth0-IdP-OPIS');

export const logout = () =>
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });

/**
 * If the params `code` and `state` exist in search params, we are in the middle
 * of an auth0 redirect and we should avoid things like immediately trying to
 * log the person in again.
 * NOTE: The react hook to use this is at `app-ui/src/utils/hooks/use-auth.ts`
 */
export const hasAuth0CallbackParams = (params: URLSearchParams) => {
  return params.has('code') && params.has('state');
};

/**
 * SSO callbacks have a particular hash that we can use to detect if we are in
 * an SSO callback
 */
export const isSsoCallbackHash = () => {
  if (isBrowser && window.location.hash) {
    const params = new URLSearchParams(window.location.hash.substring(1)); // strip leading #
    // SSO responses from auth0 route back to:
    // https://localhost:8080/site/ks-test/latest#access_token=<jwt here>&expires_in=7200&token_type=Bearer&state=https%3A%2F%2Flocalhost%3A8080%2Fsite%2Fks-test%2Flatest%26response_type%3Dtoken%20id_token%26scope%3Dopenid%20profile&id_token=<jwt here>
    return params.has('access_token') && params.has('state');
  }
  return false;
};

export const endpoint = `/plugins`;

export enum ACTIONS {
  getContent = 'getContent',
}

export interface GetContentRequest {
  type: ACTIONS.getContent;
  pluginId: string;
}

export interface GetContentResponse {
  type: ACTIONS.getContent;
  ok: boolean;
  message?: string;
  payload: Record<string, unknown>;
}

export type ActionRequests = GetContentRequest;

export type ActionResponses = GetContentResponse;

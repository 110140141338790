// backwards compatibile exports
// this file used to have utilities for types, but those really belong
// in `@knapsack/utils` so we're re-exporting them from there
// look for `type-tools.ts` in `@knapsack/utils` for the source
export { isObjKey, isObject } from '@knapsack/utils';
export type {
  GetValueOrAsyncReturnValue,
  ValueOrGetValue,
  ValueOf,
  Except,
  Simplify,
  AsyncReturnType,
  PackageJson,
  TsConfigJson,
} from '@knapsack/utils';

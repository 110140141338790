import {
  createAppApiGqlClient,
  createAppApiRestClient,
} from '@knapsack/app-api-gql-client';
import { getUserToken } from '@/utils/user-token';
import { featureFlags } from '@/utils/feature-flags';
import { captureException } from '@/utils/sentry';
import { knapsackGlobal } from '@/global';
import { ksHttpHeaders } from '@knapsack/core';
import { extractPossibleInstanceIdFromSite } from '@/core/env-and-content-src/utils';

export * from '@knapsack/app-api-gql-client';

export const appApiUrl = new URL(
  featureFlags.appApiGqlEndpoint || 'https://api.knapsack.cloud',
).origin;

async function getHeaders(): Promise<Record<string, string>> {
  const { site } = knapsackGlobal.appService.getSnapshot().context;
  const headers: Record<string, string> = {};
  headers[ksHttpHeaders.siteId] = site?.meta.siteId;
  headers[ksHttpHeaders.instanceId] = extractPossibleInstanceIdFromSite(site);

  try {
    const token: string = await getUserToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    // Muffle error here. Error is logged within getUserToken()
  }
  return headers;
}

export const appApiGql = createAppApiGqlClient({
  serverUrl: appApiUrl,
  getHeaders,
  logError: (error) => captureException(error),
});

export const appApiRest = createAppApiRestClient({
  serverUrl: appApiUrl,
  getHeaders,
  logError: (error) => captureException(error),
});

import type { RendererId } from '../renderers';

export const endpoint = `/files`;

export enum ACTIONS {
  verify = 'verify',
}

export interface VerifyData {
  type: ACTIONS.verify;
  payload: {
    path: string;
    rendererId: RendererId;
  };
}

export interface VerifyDataResponse {
  type: ACTIONS.verify;
  payload: {
    exists: boolean;
    relativePath: string;
    absolutePath: string;
    type: 'absolute' | 'relative' | 'package' | 'unknown';
  };
}

export type Actions = VerifyData;

export type ActionResponses = VerifyDataResponse;

export function trackIntercomEvent({
  type,
  metadata,
}: {
  type: string;
  metadata?: Record<string, string | number | boolean>;
}) {
  // `react-use-intercom` uses the `Intercom` global
  // we track events outside the React tree so we use the `Intercom` global directly
  metadata
    ? (window as any).Intercom?.('trackEvent', type, metadata)
    : (window as any).Intercom?.('trackEvent', type);
}

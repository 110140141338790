'use client';

import { useEffect } from 'react';
import { isProdRelease } from '@/utils/release-stage.browser';
import type { Role } from '@knapsack/types';
import * as sentry from '@/utils/sentry';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { track as vercelTrack } from '@vercel/analytics/react';
import { trackIntercomEvent } from '@/core/intercom/intercom-track-event';
import { isCypress, isUnitTesting } from './constants';

type UserInfo = {
  userId: string;
  role: Role;
  isInternalUser: boolean;
  dateCreated?: string;
  responsibility?: string;
};
const anonUser: UserInfo = {
  userId: '',
  role: 'ANONYMOUS',
  isInternalUser: false,
};
/** Currently loaded `siteId` and `orgId` */
let siteId = '';
let orgId = '';
/** Currently active user */
let user: UserInfo = anonUser;

const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

const shouldTrack = isProdRelease && !isCypress() && !isUnitTesting;
// const debugSegment = new URL(window.location.href).searchParams.has(
//   'debugSegment',
// );

/**
 * [Docs](https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/)
 */
let segment: AnalyticsBrowser | null;

if (shouldTrack && segmentWriteKey) {
  console.debug(`Segment started`);
  segment = AnalyticsBrowser.load(
    {
      writeKey: segmentWriteKey,
    },
    {
      obfuscate: true,
    },
  );
  /**
   * [Docs] https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   * Segment docs says page() should be called on init but seems to be double counting
   */
  // segment?.page();
  // segment?.debug(debugSegment);
}

type TrackEvent = {
  /** Follows "Noun Verb" format */
  type:
    | `${
        | 'Pattern'
        | 'Pattern SubPage'
        | 'Template'
        | 'Block'
        | 'Page'
        | 'Token'
        | 'Prototype'
        | 'UI Config'
        | 'User'
        | 'Demo (data)'
        | 'Demo (template)'} ${'Added' | 'Edited' | 'Deleted' | 'Duplicated'}`
    | `Branch ${'Added' | 'Committed' | 'Published' | 'Switched' | 'Deleted'}`
    | `Tokens ${'Imported' | 'Exported' | 'Import Error'}`
    | `File ${'Added' | 'Edited' | 'Deleted' | 'Downloaded'}`
    | `User Connected Accounts ${'Connect Triggered' | 'Remove Triggered'}`
    | `Design Src Modal ${'Opened' | 'Submitted' | 'Dismissed'}`
    | `Design Src ${
        | 'OAuth Request Started'
        | 'OAuth Request Complete'
        | 'OAuth Request Error'
        | 'File Added'
        | 'File Updated'
        | 'File Deleted'
        | 'Settings Page Viewed'
        | 'Add Design Source Clicked'
        | 'View Origin Source'}`
    | `Command Bar ${'Opened' | 'Used'}`;
  metadata?: Record<string, string | boolean>;
};

export function trackEvent({ type, metadata = {} }: TrackEvent) {
  // delay to next "tick" so it doesn't block current render
  setTimeout(() => {
    if (!isUnitTesting) {
      console.debug(`trackEvent: ${type}`, metadata);
    }
    if (!shouldTrack) return;
    vercelTrack(type, metadata);
    trackIntercomEvent({
      type,
      metadata,
    });
    sentry.addBreadcrumb({
      category: 'trackEvent',
      message: type,
      data: metadata,
      level: 'info',
    });
    try {
      segment?.track(type, {
        siteId,
        orgId,
        ...user,
        ...metadata,
      });
    } catch (e) {
      console.error(e);
    }
  }, 0);
}

export function removeAnalyticsUser() {
  if (!shouldTrack) return;
  user = anonUser;
  sentry.setUser(null);
  try {
    segment?.reset();
  } catch (e) {
    console.error(e);
  }
}

export function updateAnalyticsUser({
  userId,
  role,
  isInternalUser,
  dateCreated,
  responsibility,
}: UserInfo) {
  if (!shouldTrack) return;

  user = { userId, role, isInternalUser };

  sentry.setUser({
    id: userId,
    segment: role,
  });
  try {
    segment?.identify(
      userId,
      {
        role,
        isInternalUser,
        siteId,
        orgId,
        dateCreated,
        responsibility,
      },
      {},
    );
  } catch (e) {
    console.error(e);
  }
}
export function removeAnalyticsSite() {
  siteId = '';
  orgId = '';
  sentry.setTag('siteId', null);
}

export function updateAnalyticsSite({
  siteId: newSiteId,
  orgId: newOrgId,
}: {
  siteId: string;
  orgId: string;
}) {
  siteId = newSiteId;
  orgId = newOrgId;
  try {
    segment?.group(siteId, {
      siteId,
      id: siteId,
      website: `https://app.knapsack.cloud/site/${siteId}`,
      orgId,
    });
  } catch (e) {
    console.error(e);
  }
  sentry.setTag('siteId', siteId);
}

// Capture up to 20 unique properties (exclusive of pageName) on a single page.
// Capture up to 500 unique properties across all pages.
export function trackPageTypeView({
  pageName,
  metadata = {},
}: {
  pageName: string;
  metadata?: Record<string, string>;
}) {
  if (!shouldTrack) return;
  segment?.page(pageName, { ...metadata, siteId, orgId });
}

/**
 * Used to track a _type_ of a page, like "Pattern"
 * Note that if the values passed in change between renders then it won't work
 * Be sure to have static values that will always be present upon first render
 */
export function useTrackPageTypeView(
  info: Parameters<typeof trackPageTypeView>[0],
) {
  useEffect(() => {
    if (!info) return;
    trackPageTypeView(info);
    // only supposed to run on mount so we'll have `info`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function trackPageView() {
  try {
    segment?.page(siteId, orgId);
  } catch (e) {
    console.error(e);
  }
}

export function trackError(error: Error): void {
  console.error(error);
  sentry.captureException(error);
}
